import './UnderDev.css';

function UnderDev() {
  return (
    <div className="UnderDev">
      <p className="UnderDevText">This website is still under development. Thank you for your patience. </p>
    </div>
  );
}

export default UnderDev;